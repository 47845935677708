:root {
    font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin:0px;
}

div{
    align-content: center;
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:18px;
    width: 100vw; 
    font-weight: bold;
}   

img {
    height:72vh;
    margin-bottom: 11px;
   
}


p {
    font-weight: normal;
    font-size: 16px; 
}

button:hover,a:hover {
    background-color: gray;
  }

a{  
    text-decoration: none;
    color:black;
}
header{
    
    display: flex;
    justify-content: space-around;
    align-items:center;
    width:100vw;
    height: 77px;
    font-family: 'Courier New', Courier, monospace;
   
}

button {

    background-color:transparent;
    border:none;
    font-size:18px;
    cursor: pointer;
    font-weight: bold;
}
.about{
    font-size:42px;
    width:93%;
    font-family: 'Courier New', Courier, monospace;   
}

@media (max-width: 420px){
    img{
   
       width:88vw;
       height: auto;
   }
}
